import styled from "styled-components";

export const SubHeadingWraper = styled.div`
  width: fit-content;
  .Sub-heading {
    width: fit-content;
    color: ${(props) => props.theme.colors.black_1000};
    line-height: 36px;
    margin: 0px 0px 24px 0px;
    font-family: "NunitoSansRegular" !important;
    font-size: 36px;
    font-weight: 400;
  }
`;
