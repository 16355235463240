import styled from "styled-components";
export const RecruitmentWrapper = styled.div `
  margin-bottom: 24px;

  .Radio-wrapper {
    padding: 8px 12px;
    border-radius: 0;
    display: flex;
    border: 1px solid #d5d4d2;
    margin-bottom: 24px;
    min-height: 40px;
    width: 63.5%;
  }
  .ant-space {
    gap: 16px !important;
  }
  .ant-radio .ant-radio-inner {
    width: 20px;
    height: 20px;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #ffffff;
    background-color: #ff9900;
  }
  .ant-radio-wrapper span {
    font-family: "NunitoSansRegular";
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${(props) => props.theme.colors.black_1000};
  }
  .ant-radio-wrapper span.ant-radio + * {
    padding: 0px 12px;
    line-height: 24px;
  }
  .ant-radio-wrapper span .package-heading {
    font-family: "NunitoSansRegular";
    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    margin-right: 3px;
  }
  .ant-radio:hover span {
    border: 1px solid #ff9900;
    transition: all 0.2s;
  }
  .ant-radio-wrapper:hover .ant-radio span {
    border: 1px solid #ff9900;
    transition: all 0.2s;
  }
  .price {
    margin-right: 8px;
  }
  .ant-btn-primary {
    font-family: "NunitoSansBold";
    font-size: 14px;
    font-weight: 700;
    background-color: ${(props) => props.theme.colors.brown_200} !important;
    color: ${(props) => props.theme.colors.white};
    padding: 12px 32px;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 1.059px 21.189px 0px rgba(255, 255, 255, 0.55);
    height: fit-content;
    line-height: 16px;
    margin: 12px 0px 12px 0px;
    @media (max-width: 500px) {
      width: fit-content;
      margin: auto;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.brown_100} !important;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;
export const TableWraper = styled.div `
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }
  .ant-table-thead > tr > th {
    border-bottom: none;
    background: ${(props) => props.theme.colors.black_100};
    font-family: "NunitoSansRegular";
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 16.8px;
    padding: 12px 0px 12px 16px;
    color: ${(props) => props.theme.colors.black_1000};
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    font-size: 14px;
    font-weight: 400;
    font-family: "NunitoSansRegular";
    line-height: 21px;
    padding: 8px 0px 8px 16px;
    color: ${(props) => props.theme.colors.black_800};
    border-bottom: 1px solid ${(props) => props.theme.colors.black_400};
  }
  .job-title-column {
    color: ${(props) => props.theme.colors.black_1000} !important;
    font-weight: 600 !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0px;
  }
  .ant-table-container table > tbody > tr:last-child td:last-child {
    border-bottom-right-radius: 0px;
  }
  .ant-table-container table > tbody > tr:last-child td:first-child {
    border-bottom-left-radius: 0px;
  }
  .table-row-White {
    background: ${(props) => props.theme.colors.white};
  }
  .table-row-lite {
    background: #f2f1f04d;
  }
  .centered-cell {
    text-align: center !important;
  }
`;
