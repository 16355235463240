const theme = {
  colors: {
    black_1000: "#32302E",
    black_900: "#423F3C",
    black_800: "#55524D",
    black_700: "#78736D",
    black_600: "#938F8A",
    black_500: "#A5A19D",
    black_400: "#C1BFBC",
    black_300: "#FFDFB0",
    black_200: "#D5D4D2",
    black_100: "rgba(242, 241, 240, 0.3)",
    brown_100: "#FFAD33",
    brown_200: "#FF9900",
    brown_300: "#B56D00",
    blue: "#0C76F3",
    white: "#ffffff",
    greenPrimary: "#34CB61",
    greenSecondry: "#EBFAEF",
    yellowPrimary: "#FFDC00",
    yellowSecondry: "#FFFCE6",
    redPrimary: "#F43E5C",
    redSecondry: "#FEECEF",
  },

  font: {
    family: "Nunito Sans",
    weight: {
      thin: 100,
      extraLight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
    },
  },

  media: {
    md: 768,
    sm: 500,
    lg: 968,
    xl: 1440,
  },

  utils: {},
};

export default theme;
