import { Button } from "antd";
import { HeroButtonWraper } from "./styles";
import { Link } from "react-router-dom";
import Add from "src/Assets/Icons/Add";

const FormActionButn = ({ children, to }: any) => {
  return (
    <HeroButtonWraper>
      {/* <Link to={to}> */}
      <Button className="hero-button">{children}</Button>
      {/* </Link> */}
    </HeroButtonWraper>
  );
};

export default FormActionButn;
