import styled from 'styled-components';
import NavBackground from './../../../Assets/Images/navbar-bg.svg';
export const LayoutWrapper = styled.div`
  height: 100%;
  background: #ffffff;
  .ant-layout-content {
    background: #ffff;
  }
  .ant-layout {
    background: #ffff;
  }
  .site-layout {
    margin-top: 24px;
    overflow: hidden;
  }
  .content-padding {
    padding: 40px;
    padding-top: 15px;
    overflow: auto;
  }
  .qickbook-btn {
    padding: 20px 10px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    font-size: 14px;
    line-height: 16.8px;
    text-align: left;
    color: rgb(255, 255, 255);
    font-weight: 700;
  }

  .qickbook-btn:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
    color: rgb(255, 255, 255) !important;
  }

  .ant-layout-header {
    padding: 8px 72px;
    height: 100% !important;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.23) 0px 6px 6px 0px,
      rgba(0, 0, 0, 0.19) 0px 10px 10px 0px;

    background: url(${NavBackground});
    background-size: cover;
  }
  .ant-layout-header {
    padding: 15px 72px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-nav-wrapper {
      display: flex;
    }
    .btn-cta {
      width: 94px;
      height: 40px;
      padding: 10px 12px 10px 10px;
      border-radius: 4px 4px 4px 4px;
      opacity: 0px;
      background: #ffffff1a;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.8px;
      text-align: left;
      color: #ffffff;

      font-weight: 700;
      :hover {
        background: rgba(255, 255, 255, 0.2);
        color: #ffffff;
      }
    }
  }
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px;
  }
  @media (max-width: 1024px) {
    .ant-layout-header {
      padding-inline: 10px;
    }

    .ant-menu-light.ant-menu-root.ant-menu-inline,
    .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-inline,
    .ant-menu-light.ant-menu-root.ant-menu-vertical,
    .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-vertical {
      border-inline-end: none;
    }
    .content-padding {
      padding: 10px;
    }
  }
`;
