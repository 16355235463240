import CityState from "src/Assets/Content/CityStates/data";
interface Location {
  key: string;
  value: string;
  label: string;
}

export default function getLocations() {
  const transformCityStateData = () => {
    const cityStateData = CityState();
    const transformedLocations: Location[] = [];

    for (const state in cityStateData) {
      if (cityStateData.hasOwnProperty(state)) {
        const location: Location = {
          key: `${transformedLocations.length + 1}`,
          value: state,
          label: state
        };
        transformedLocations.push(location);
      }
    }
    return transformedLocations;
  };

  return transformCityStateData();
}
