export default function ErrorIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99992 1.83301C4.32659 1.83301 1.33325 4.82634 1.33325 8.49967C1.33325 12.173 4.32659 15.1663 7.99992 15.1663C11.6733 15.1663 14.6666 12.173 14.6666 8.49967C14.6666 4.82634 11.6733 1.83301 7.99992 1.83301ZM7.49992 5.83301C7.49992 5.55967 7.72659 5.33301 7.99992 5.33301C8.27325 5.33301 8.49992 5.55967 8.49992 5.83301V9.16634C8.49992 9.43967 8.27325 9.66634 7.99992 9.66634C7.72659 9.66634 7.49992 9.43967 7.49992 9.16634V5.83301ZM8.61325 11.4197C8.57992 11.5063 8.53325 11.573 8.47325 11.6397C8.40659 11.6997 8.33325 11.7463 8.25325 11.7797C8.17325 11.813 8.08659 11.833 7.99992 11.833C7.91325 11.833 7.82659 11.813 7.74659 11.7797C7.66659 11.7463 7.59325 11.6997 7.52659 11.6397C7.46659 11.573 7.41992 11.5063 7.38659 11.4197C7.35325 11.3397 7.33325 11.253 7.33325 11.1663C7.33325 11.0797 7.35325 10.993 7.38659 10.913C7.41992 10.833 7.46659 10.7597 7.52659 10.693C7.59325 10.633 7.66659 10.5863 7.74659 10.553C7.90659 10.4863 8.09325 10.4863 8.25325 10.553C8.33325 10.5863 8.40659 10.633 8.47325 10.693C8.53325 10.7597 8.57992 10.833 8.61325 10.913C8.64659 10.993 8.66659 11.0797 8.66659 11.1663C8.66659 11.253 8.64659 11.3397 8.61325 11.4197Z"
        fill="#F43E5C"
      />
    </svg>
  );
}
