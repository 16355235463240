import styled from "styled-components";

export const TabsHeader = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;
export const TabsWraper = styled.div`
  width: 100%;
  display: block !important;
  :where(.css-dev-only-do-not-override-oevy7f).ant-tabs > .ant-tabs-nav,
  :where(.css-dev-only-do-not-override-oevy7f).ant-tabs > div > .ant-tabs-nav {
    position: relative;
    flex: none;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .ant-tabs {
    justify-content: center;
    align-items: center;
  }
  .ant-tabs > .ant-tabs-nav {
    justify-content: center;
    align-items: center;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    padding: 4px 0px;
    background: ${(props) => props.theme.colors.black_200};
    border-radius: 4px;
  }
  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0px 4px;
    background: #fff;
    padding: 10px 0px;
    border-radius: 4px;
  }
  .ant-tabs .ant-tabs-tab {
    background: #fff;
    min-width: 137px;
    padding: 10px 12px;
    justify-content: center;
    margin: 0px 4px;
    color: ${(props) => props.theme.colors.black_1000};
    font-family: "NunitoSansBold";
    font-weight: 600;
    font-size: 14px;
    line-height: 16.8px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.black_400};
  }
  .ant-tabs .ant-tabs-tab:hover {
    color: ${(props) => props.theme.colors.black_1000};
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
    color: #fff;
    background: ${(props) => props.theme.colors.brown_200};
    border-radius: 4px;
    font-weight: 700;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
    color: #fff;
  }
  .ant-tabs .ant-tabs-tab-btn:focus {
    color: ${(props) => props.theme.colors.black_1000};
  }
`;
