import Layout from "src/Components/Layout/Main/index";
import Theme from "src/Styles/theme";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Home from "./Home";
import ApplicationDetails from "./ApplicationDetails";
import Login from "./Auth/Login";
import ForgotPassword from "./Auth/ForgotPassword";
import { AppRoutes } from "src/Components/Constant/routes";
import ResetPassword from "./Auth/ResetPassword";
import CustomerDetails from "./CustomerDetails";
import Recruitment from "./Recruitment";

function App() {
  const layoutWrapper = (component: JSX.Element) => (
    <Layout>{component}</Layout>
  );
  return (
    
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Routes>
          <Route path={AppRoutes.home} element={layoutWrapper(<Home />)} />
          <Route path={AppRoutes.login} element={layoutWrapper(<Login />)} />
          <Route path={AppRoutes.customer} element={layoutWrapper(<CustomerDetails />)} />
          <Route path={AppRoutes.recruitment} element={layoutWrapper(<Recruitment />)} />

          <Route
            path={AppRoutes.forgot}
            element={layoutWrapper(<ForgotPassword />)}
          />
          <Route
            path={AppRoutes.reset}
            element={layoutWrapper(<ResetPassword />)}
          />
          <Route
            path={AppRoutes.application}
            element={layoutWrapper(<ApplicationDetails />)}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
