import styled from "styled-components";
export const HeroButtonWraper = styled.div`
  display: flex;
  justify-content: end;
.ant-btn-icon{
    margin-inline-end: 0px !important;
}
    .hero-button {
      font-family: "NunitoSansBold";
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      background-color: ${(props) => props.theme.colors.brown_200};
      color: ${(props) => props.theme.colors.white};
      padding: 12px 24px 12px 20px;
      border: none;
      border-radius: 4px;
      height: fit-content;
      display: flex;
      align-items: center;
      @media (max-width: 500px) {
        width: fit-content;
        margin: auto;
      }
      &:hover {
        background-color: ${(props) => props.theme.colors.brown_100} !important;
        color: ${(props) => props.theme.colors.white} !important;
      }
      &:focused {
        background-color: ${(props) => props.theme.colors.brown_100} !important;
        color: ${(props) => props.theme.colors.white} !important;
      }
    }
    @media (max-width: 500px) {
      flex-direction: column;
      text-align: center;
    }

  }
`;
