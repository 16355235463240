import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { ParentWrapper } from "./Styles/global";
import App from "./Pages/App";
import { ConfigProvider } from "antd";
import FontStyles from "./fontStyles";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ParentWrapper>
        <FontStyles />
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#0000FE",
            },
          }}
        >
          <App />
        </ConfigProvider>
      </ParentWrapper>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
