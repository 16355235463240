import { ParagraphWraper } from "./styles";
interface ParagraphProps {
  children: React.ReactNode;
  className?: string;
}
const Paragraph: React.FC<ParagraphProps> = ({ className, children }) => {
  return (
    <ParagraphWraper>
      <p className={className}>{children}</p>
    </ParagraphWraper>
  );
};

export default Paragraph;
