export default function StatusFailed() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 1.66699C5.40829 1.66699 1.66663 5.40866 1.66663 10.0003C1.66663 14.592 5.40829 18.3337 9.99996 18.3337C14.5916 18.3337 18.3333 14.592 18.3333 10.0003C18.3333 5.40866 14.5916 1.66699 9.99996 1.66699ZM12.8 11.917C13.0416 12.1587 13.0416 12.5587 12.8 12.8003C12.675 12.9253 12.5166 12.9837 12.3583 12.9837C12.2 12.9837 12.0416 12.9253 11.9166 12.8003L9.99996 10.8837L8.08329 12.8003C7.95829 12.9253 7.79996 12.9837 7.64163 12.9837C7.48329 12.9837 7.32496 12.9253 7.19996 12.8003C6.95829 12.5587 6.95829 12.1587 7.19996 11.917L9.11663 10.0003L7.19996 8.08366C6.95829 7.84199 6.95829 7.44199 7.19996 7.20033C7.44163 6.95866 7.84163 6.95866 8.08329 7.20033L9.99996 9.11699L11.9166 7.20033C12.1583 6.95866 12.5583 6.95866 12.8 7.20033C13.0416 7.44199 13.0416 7.84199 12.8 8.08366L10.8833 10.0003L12.8 11.917Z"
        fill="#F43E5C"
      />
    </svg>
  );
}
