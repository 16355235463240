export const data = [
  {
    title: "Job Applications",
    tableData: [
      {
        key: "1",
        date_submitted: "10-19-2023",
        job_title: "Sales Manager",
        job_id: "3916",
        start_date: "10-19-2023",
        end_date: "11-18-2023",
        employer_name: "TEST CO",
        payment_method: "Check",
      },
      // Additional rows for Page 1 if needed
    ],
  },
  {
    title: "Job Orders",
    tableData: [
      {
        key: "2",
        date_submitted: "08-28-2022",
        job_title: "Hardware Engineer",
        job_id: "3901",
        start_date: "08-28-2022",
        end_date: "09-27-2022",
        employer_name: "Express Scripts Pharmacy, Inc.",
        payment_method: "Check",
      },
      // Additional rows for Page 2 if needed
    ],
  },
  {
    title: "Customers",
    tableData: [
      {
        key: "3",
        date_submitted: "07-05-2022",
        job_title: "Senior Management...",
        job_id: "3894",
        start_date: "07-05-2022",
        end_date: "07-15-2022",
        employer_name: "Sambanova System. Inc.",
        payment_method: "Credit Card",
      },
      // Additional rows for Page 3 if needed
    ],
  },
];
