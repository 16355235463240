export default function StatusSuccess() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 1.66699C5.40829 1.66699 1.66663 5.40866 1.66663 10.0003C1.66663 14.592 5.40829 18.3337 9.99996 18.3337C14.5916 18.3337 18.3333 14.592 18.3333 10.0003C18.3333 5.40866 14.5916 1.66699 9.99996 1.66699ZM13.9833 8.08366L9.25829 12.8087C9.14163 12.9253 8.98329 12.992 8.81663 12.992C8.64996 12.992 8.49163 12.9253 8.37496 12.8087L6.01663 10.4503C5.77496 10.2087 5.77496 9.80866 6.01663 9.56699C6.25829 9.32533 6.65829 9.32533 6.89996 9.56699L8.81663 11.4837L13.1 7.20033C13.3416 6.95866 13.7416 6.95866 13.9833 7.20033C14.225 7.44199 14.225 7.83366 13.9833 8.08366Z"
        fill="#34CB61"
      />
    </svg>
  );
}
