import { Button, Col, Form, Input, Row, Select, Image, Checkbox } from 'antd';
import getLocations from 'src/Assets/Content/statesFn';
import ErrorMessage from 'src/Components/Shared/ErrorMessage';
import { FormWraper } from 'src/Styles/global';
import { useState, useEffect } from 'react';
import Print from 'src/Assets/Icons/print-icon.svg';
import Trash from 'src/Assets/Icons/trash.svg';
import DropDownIcon from 'src/Assets/Icons/DropDown';
import FormActionButn from 'src/Components/Shared/FormActionButn';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import HearFromType from 'src/Assets/Content/HearFrom/data';
import CountryList from 'src/Assets/Content/Countries/data';
import CustomerTypeList from 'src/Assets/Content/CustomerType/data';
import SubHeading from 'src/Components/Shared/SubHeading';
import { EditCustomerAction } from 'src/Redux/App/Actions/Auth';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loading as stateLoading } from 'src/Redux/App';
import NotificationModal from 'src/Components/Pages/Auth/NotificationModal';
import WarningIcon from 'src/Assets/Icons/WarningIcon';
interface CustomerDetail {
  users_email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zipCode: number;
  role: string;
  address2: string;
  faxNumber: string;
  country: string;
  customerType: string;
  customerDiscount: number;
  rewardPoints: number;
  hearFrom: string;
  referredBy: string;
  newsletter: boolean;
  users_password: string;
  id: number;
  accountNumber: string;
}
const CustomerDetails = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);

  const locations: any[] = getLocations();
  const countryList: any[] = CountryList;
  const customerTypeList: any[] = CustomerTypeList;
  const hearFromType: any[] = HearFromType;
  const loading = useSelector(stateLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const record = location.state && location.state.record;
  const [customer, setCustomer] = useState<CustomerDetail>({
    id: 0,
    users_email: '',
    users_password: '',
    firstName: '',
    lastName: '',
    companyName: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipCode: 0,
    role: '',
    address2: '',
    faxNumber: '',
    country: '',
    customerType: '',
    customerDiscount: 0,
    rewardPoints: 0,
    hearFrom: '',
    referredBy: '',
    newsletter: false,
    accountNumber: '',
  });
  const [verifyPassword, setVerifyPassword] = useState('');
  useEffect(() => {
    delete record.registerDate;
    setCustomer(record);
  }, [1000]);
  const { Option } = Select;

  const Saved = () => {
    if (verifyPassword && verifyPassword !== customer.users_password) {
      setModalVisible(true)
    } else {
      if (!updatePassword) {
        delete (customer as Partial<typeof customer>)?.users_password;
    }
      dispatch<any>(EditCustomerAction(navigate, customer, customer?.id));
    }
  };
  const Discard = () => {
    navigate('/', { state: { tab: '3' } });
  };
  const closeModal = () => {
    setModalVisible(false);
  };
  return (
    <>
      <Row justify={'space-between'}>
        <Col
          xs={20}
          sm={20}
          md={20}
          lg={20}
          xl={20}
          xxl={20}
        >
          <SubHeading>Customer Details</SubHeading>
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          xxl={4}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            gap: '8px',
          }}
        >
          <div style={{ position: 'fixed', display: 'flex' }}>
            <FormActionButn>
              <Image
                src={Print}
                preview={false}
                className='print-icon'
                onClick={() => window.print()}
                style={{
                  marginTop: '2px',
                }}
              />
            </FormActionButn>
            <FormActionButn>
              <Image
                src={Trash}
                preview={false}
                className='print-icon'
              />
            </FormActionButn>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={16}>
          <FormWraper>
            <Form layout='vertical'>
              <Form.Item
                label='First Name'
                name='firstName'
                rules={[
                  {
                    required: customer.firstName ? false : true,
                    message: (
                      <ErrorMessage message='Please input  First Name ' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={customer.firstName}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          firstName: e.target.value, // Update only the firstName field
                        }));
                      }}
                      placeholder='first Name'
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label='Last Name'
                name='lastName'
                rules={[
                  {
                    required: customer.lastName ? false : true,
                    message: <ErrorMessage message='Please input Last Name' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      onChange={(e) => {
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          lastName: e.target.value, // Update only the firstName field
                        }));
                      }}
                      value={customer.lastName}
                      placeholder='Last Name'
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                label='Phone'
                name='phone'
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message='Please input phone' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      onChange={(e) => {
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          phone: e.target.value, // Update only the firstName field
                        }));
                      }}
                      value={customer.phone}
                      placeholder='03327655779'
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* <Form.Item
                label="Fax Number"
                name="faxNumber"
                rules={[
                  {
                    required: customer.faxNumber ? false : true,
                    message: <ErrorMessage message="Please input Fax Number" />,
                  },
                ]}

              >
                <Row>
                  <Col span={23}>
                    <Input onChange={(e) => {
                      setCustomer(customer => ({
                        ...customer, // Keep previous data
                        faxNumber: e.target.value // Update only the firstName field
                      }));
                    }} value={customer?.faxNumber} placeholder="623678" />
                  </Col>
                </Row>
              </Form.Item> */}
              <Form.Item
                label='Email'
                name='users_email'
                rules={[
                  {
                    required: customer.users_email ? false : true,
                    message: (
                      <ErrorMessage message='Please input Users Email' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='email'
                      onChange={(e) => {
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          users_email: e.target.value, // Update only the firstName field
                        }));
                      }}
                      value={customer.users_email}
                      placeholder='example@gmail.com'
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                label='Password'
                name='users_password'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input Users Password' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='password'
                      onChange={(e) => {
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          users_password: e.target.value, // Update only the firstName field
                        }));
                        setUpdatePassword(true)
                      }}
                      placeholder='Enter your password'
                      value={customer?.users_password}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label='Confirm Password'
                name='confirmPassword'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input Confirm Password' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='password'
                      onChange={(e) => {
                        setUpdatePassword(true)
                        setVerifyPassword(e.target.value);
                      }}
                      placeholder='Enter your password'
                      value={verifyPassword}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label='Account #'
                name='accountNumber'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input Company name' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      onChange={(e) => {
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          accountNumber: e.target.value, // Update only the firstName field
                        }));
                      }}
                      value={customer?.accountNumber}
                      placeholder='Hello Code'
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                label='Company name'
                name='companyName'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input Company name' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      onChange={(e) => {
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          companyName: e.target.value, // Update only the firstName field
                        }));
                      }}
                      value={customer?.companyName}
                      placeholder='Hello Code'
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                label='Street Address'
                name='address'
                rules={[
                  {
                    required: customer.address ? false : true,
                    message: (
                      <ErrorMessage message='Please input Street Address' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      onChange={(e) => {
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          address: e.target.value, // Update only the address field
                        }));
                      }}
                      value={customer?.address}
                      placeholder='Address'
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                label='Street Address 2'
                name='address2'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input Street Address 2' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      onChange={(e) => {
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          address2: e.target.value, // Update only the firstName field
                        }));
                      }}
                      value={customer?.address2}
                      placeholder='Address'
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Col span={23}>
                <Form.Item
                  label='City'
                  rules={[
                    {
                      required: true,
                      message: (
                        <ErrorMessage message='Please input your city' />
                      ),
                    },
                  ]}
                >
                  <Input
                    value={customer?.city}
                    defaultValue={customer?.city}
                    onChange={(e: any) => {
                      setCustomer((customer) => ({
                        ...customer, // Keep previous data
                        city: e.target.value, // Update only the firstName field
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={23}>
                <Form.Item
                  label='State'
                  rules={[
                    {
                      required: true,
                      message: (
                        <ErrorMessage message='Please input your state' />
                      ),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    defaultValue={customer?.state}
                    value={customer.state}
                    onChange={(value) => {
                      setCustomer((customer) => ({
                        ...customer, // Keep previous data
                        state: value, // Update only the firstName field
                      }));
                    }}
                  >
                    {locations.map((location) => (
                      <Option
                        key={location.key}
                        value={location.label}
                        label={location.label}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '13px',
                          }}
                        >
                          {location.label}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Form.Item
                label="Select"
                name="city"
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message="Please input City, State" />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      placeholder="227134 North Scottsdale Road"
                      suffixIcon={<DropDownIcon />}
                      showSearch
                      onChange={(value) => {
                        setCustomer(customer => ({
                          ...customer, // Keep previous data
                          city: value // Update only the firstName field
                        }));
                      }}
                      value={customer.city}
                    >
                      {locations.map((location: any) => (
                        <Option key={location.key} value={location.label} label={location.label} >
                          <div style={{ display: "flex", alignItems: "center", gap: "13px", }} >  {location.label} </div>
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Form.Item> */}

              <Form.Item
                label='Zip/Post Code'
                name='zipCode'
                rules={[
                  {
                    required: customer.zipCode ? false : true,
                    message: (
                      <ErrorMessage message='Please input Zip/Post Code' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='number'
                      onChange={(e) => {
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          zipCode: Number(e.target.value), // Update only the firstName field
                        }));
                      }}
                      value={customer?.zipCode}
                      placeholder='433556'
                    />
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                label='Country'
                name='country'
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message='Please input Country' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      placeholder='USA'
                      suffixIcon={<DropDownIcon />}
                      showSearch
                      onChange={(value) => {
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          country: value, // Update only the firstName field
                        }));
                      }}
                      value={customer?.country}
                    >
                      {countryList.map((country: any) => (
                        <Option
                          key={country}
                          value={country}
                          label={country}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '13px',
                            }}
                          >
                            {' '}
                            {country}{' '}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Form.Item>

              {/* <Form.Item
                label="Customer Type"
                name="customerType"
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message="Please input  Customer Type" />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      placeholder="b2b, b2c"
                      suffixIcon={<DropDownIcon />}
                      showSearch
                      onChange={(value) => {
                        setCustomer(customer => ({
                          ...customer, // Keep previous data
                          customerType: value // Update only the firstName field
                        }));
                      }}
                      value={customer.customerType}
                    >
                      {customerTypeList.map((type: any) => (
                        <Option key={type} value={type} label={type} >
                          <div style={{ display: "flex", alignItems: "center", gap: "13px", }} >  {type} </div>
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Form.Item> */}

              {/* <Form.Item
                label="Customer Discount"
                name="customerDiscount"

                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message="Please input Customer Discount" />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type="number"
                      onChange={(e) => {
                        setCustomer(customer => ({
                          ...customer, // Keep previous data
                          customerDiscount: Number(e.target.value) // Update only the firstName field
                        }));
                      }} defaultValue={customer?.customerDiscount} placeholder="0.00" />
                  </Col>
                </Row>
              </Form.Item> */}
              {/* <Row>
                <Col span={23}>
                  <p style={{
                    marginBottom: '20px',
                    fontFamily: "PopinsMedium",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "17px"
                  }}>
                    Newsletter <Checkbox checked={customer.newsletter} onChange={(e) => {
                      setCustomer(customer => ({
                        ...customer, // Keep previous data
                        newsletter: e.target.checked // Update only the firstName field
                      }));
                    }}>

                    </Checkbox>
                  </p>
                </Col>
              </Row> */}

              {/* <Form.Item
                label="Reward Points"
                name="rewardPoints"
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message="Please input Reward Points" />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input onChange={(e) => {
                      setCustomer(customer => ({
                        ...customer, // Keep previous data
                        rewardPoints: Number(e.target.value) // Update only the firstName field
                      }));
                    }} defaultValue={customer?.rewardPoints} placeholder="0" />
                  </Col>
                </Row>
              </Form.Item> */}

              {/* <Form.Item
                label="Hear From"
                name="hearFrom"
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message="Please input Hear From" />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      placeholder="Hear From"
                      suffixIcon={<DropDownIcon />}
                      showSearch
                      onChange={(value) => {

                        setCustomer(customer => ({
                          ...customer, // Keep previous data
                          hearFrom: value
                        }));

                      }}
                      value={customer.hearFrom}
                    >
                      {hearFromType.map((herefrom: any) => (
                        <Option key={herefrom} value={herefrom} label={herefrom} >
                          <div style={{ display: "flex", alignItems: "center", gap: "13px", }} >  {herefrom} </div>
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Form.Item> */}
              <Form.Item
                label='PSUSA notes'
                name='referredBy'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input PSUSA notes' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      onChange={(e) => {
                        setCustomer((customer) => ({
                          ...customer, // Keep previous data
                          referredBy: e.target.value,
                        }));
                      }}
                      value={customer?.referredBy}
                      placeholder={
                        customer?.referredBy
                          ? customer?.referredBy
                          : 'Not Provided'
                      }
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* save */}
              <Button
                loading={loading}
                type='primary'
                onClick={() => Saved()}
                htmlType='submit'
              >
                Save
              </Button>

              <Button
                style={{ position: 'relative', left: '5px' }}
                onClick={() => Discard()}
                className='search-butn'
                htmlType='submit'
              >
                Cancel
              </Button>
            </Form>
          </FormWraper>
        </Col>
        <NotificationModal
          visible={isModalVisible}
          onClose={closeModal}
          content={'Password and confirm password should be same'}
          icon={<WarningIcon />}
        />
      </Row>
    </>
  );
};

export default CustomerDetails;
