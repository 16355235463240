import { Button } from "antd";
import { HeroButtonWraper } from "./styles";
import { Link } from "react-router-dom";
import Add from "src/Assets/Icons/Add";

const HeroButton = ({ children, to }: any) => {
  return (
    <HeroButtonWraper>
      <Button className="hero-button" icon={<Add />}>
        {children}
      </Button>
    </HeroButtonWraper>
  );
};

export default HeroButton;
