import { AuthHeadingWraper } from "./styles";

const AuthHeading = ({ children }: any) => {
  return (
    <AuthHeadingWraper>
      <h4 className="auth-heading">{children}</h4>
    </AuthHeadingWraper>
  );
};

export default AuthHeading;
