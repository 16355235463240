import React from "react";

export default function SucsessIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0003 4.66699C15.1437 4.66699 4.66699 15.1437 4.66699 28.0003C4.66699 40.857 15.1437 51.3337 28.0003 51.3337C40.857 51.3337 51.3337 40.857 51.3337 28.0003C51.3337 15.1437 40.857 4.66699 28.0003 4.66699ZM39.1537 22.6337L25.9237 35.8637C25.597 36.1903 25.1537 36.377 24.687 36.377C24.2203 36.377 23.777 36.1903 23.4503 35.8637L16.847 29.2603C16.1703 28.5837 16.1703 27.4637 16.847 26.787C17.5237 26.1103 18.6437 26.1103 19.3203 26.787L24.687 32.1537L36.6803 20.1603C37.357 19.4837 38.477 19.4837 39.1537 20.1603C39.8303 20.837 39.8303 21.9337 39.1537 22.6337Z"
        fill="#34CB61"
      />
    </svg>
  );
}
