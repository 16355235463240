import styled from "styled-components";

export const FooterWraper = styled.footer`
display: flex;
flex-direction: row;
padding: 15px 60px;
width: 100%;

background-color: ${(props) => props.theme.colors.black_200};
@media (max-width: 767px) {
  flex-direction:column;
  padding: 15px 10px;
}

  .copyright{
      color: ${(props) => props.theme.colors.black_1000};
    font-family: 'NunitoSansRegular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    padding:5px 50px;
    text-align:center;
    width:100%;
    @media (max-width: 767px) {
      padding: 15px 0px;
      text-align:center;
  }
  }
}
`;

export const FooterWraperFixed = styled.footer`
display: flex;
flex-direction: row;
padding: 15px 60px;
position: absolute;
bottom: -70px;
width: 100%;


background-color: ${(props) => props.theme.colors.black_200};
@media (max-width: 767px) {
  flex-direction:column;
  padding: 15px 10px;
}

  .copyright{
      color: ${(props) => props.theme.colors.black_1000};
    font-family: 'NunitoSansRegular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    padding:5px 50px;
    text-align:center;
    width:100%;
    @media (max-width: 767px) {
      padding: 15px 0px;
      text-align:center;
  }
  }
}
`;
