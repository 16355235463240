import { Link } from "react-router-dom";
import { FooterWraper, FooterWraperFixed } from "./styles";

const Footer = ({ fixed, style }: any) => {
  const FooterComponent = fixed ? FooterWraperFixed : FooterWraper;
  return (
    <FooterComponent className="footer" style={style}>
      <div className="copyright">
        © Placement Services USA, Inc. 2024. All rights reserved.
      </div>
    </FooterComponent>
  );
};

export default Footer;
