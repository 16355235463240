import axios, { AxiosResponse } from "axios";
import { requestStart, requestCompleted, requestSuccess } from "../..";
import {  notification } from "antd";
import { Dispatch } from "redux";


interface Search {
  endDate:string,
  startDate:string,
  keyword : string,
  sortBy:string,
  sortOrder:string,

}

export function JobsAction() {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/job/submitted/list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
        )
        .then((res: AxiosResponse) => {
          if (res && res.data) {
            console.log(res.data.data)
            dispatch(requestSuccess(res.data.data));
          }
        })
        .catch((error) => {
          
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function SearchJobAction(searchObj:Search) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/job/search/submited?endDate=${searchObj.endDate}&startDate=${searchObj.startDate}&keyword=${searchObj.keyword}&sortBy=${searchObj.sortBy}&sortOrder=${searchObj.sortOrder}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
        )
        .then((res: AxiosResponse) => {
          if (res && res.data) {
            console.log(res.data,"datat")
            dispatch(requestSuccess(res.data));
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}


export function SortJobAction(field:any) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/job/sorted/list?sortBy=${field.key}&sortOrder=${field.order}&keyword=${field.keyword}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response && response.data) {
        console.log(response.data.data);
        dispatch(requestSuccess(response.data.data));
      }
    } catch (error:any) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.message,
      });
    }
  };
  
}

export function VarifyJobAction(id:number,obj:any,data:any,checkedItems:any,navigate:any) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    obj['diaplayItem']=checkedItems;
    obj['packagesId']= Number(localStorage.getItem('pakageId'))
    const requestBody = {...obj,...data}
    try {
      axios
        .patch(`${process.env.REACT_APP_API_BASE_URL}/job/varification?id=${id}`,requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
        )
        .then((res: AxiosResponse) => {
          if (res && res.data) {
            dispatch(requestCompleted());
            navigate('/')
            localStorage.removeItem('add')
            localStorage.removeItem('pakageId')
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}


export function AddApplication(obj:any,data:any,checkedItems:any,navigate:any) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    obj['diaplayItem']=checkedItems;
    obj['status']="SUBMITTED";
    obj['packagesId'] = Number(localStorage.getItem('pakageId'))
    const requestBody = {...obj,...data}
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/job/add`,requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
        )
        .then((res: AxiosResponse) => {
          if (res && res.data) {
            navigate('/')
            localStorage.removeItem('pakageId')
            localStorage.removeItem('add')
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}


export function sendInvoice(data:any) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/quickbooks/add-invoice`,data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
        )
        .then((res: AxiosResponse) => {
          if (res && res.data) {
            dispatch(requestCompleted());
            console.log(res.data)
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}






