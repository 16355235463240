import React, { useState } from "react";
import Paragraph from "src/Components/Shared/Peragraph";
import SubHeading from "src/Components/Shared/SubHeading";
import { FormWraper } from "src/Styles/global";
import { Button, Col, Form, Image, Input, Row } from "antd";
import SuccessIcon from "src/Assets/Icons/SuccessIcon";
import print from "src/Assets/Icons/print-icon.svg";
import NotificationModal from "src/Components/Pages/Auth/NotificationModal";
import { ForgetPasswordWraper } from "./styles";
import ErrorIcon from "src/Assets/Icons/ErrorIcon";
import WarningIcon from "src/Assets/Icons/WarningIcon";
import { useNavigate } from "react-router-dom";
import { ForgetPasswordAction } from "src/Redux/App/Actions/Auth";
import { useDispatch, useSelector } from "react-redux";
import { loading as stateLoading } from "src/Redux/App";

const ForgotPassword = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalVisible2, setModalVisible2] = useState(false);
  const loading = useSelector(stateLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModal = () => {
    setModalVisible(false);
    setModalVisible2(false);
  };
  const onFinish = (values: any) => {
    dispatch<any>(
      ForgetPasswordAction(
        {
          users_email: values.email,
          redirectUrl: process.env.REACT_APP_REDIRECT_URL,
        },
        notify
      )
    );
  };
  let notify = () => {
    setModalVisible(true);
    // setTimeout(() => {
    //   setModalVisible(false);
    // }, 2000);
  };
  const onFinishFailed = () => {
    setModalVisible2(true);
  };

  return (
    <>
      <ForgetPasswordWraper>
        <Row justify={"space-between"}>
          <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
            <>
              <SubHeading>Password Reset</SubHeading>
              <Paragraph className="smRegular">
                Please enter the email address associated with your Account. We
                will send an email to this address with a link to reset your
                password.
              </Paragraph>
            </>
          </Col>
          <Col
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            xxl={2}
            style={{ textAlign: "end" }}
          >
            <Image
              src={print}
              preview={false}
              className="print-icon"
              onClick={() => window.print()}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={20} sm={20} md={20} lg={18} xl={10} xxl={10}>
            <FormWraper>
              <Form
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className="error-icon">
                          <ErrorIcon />
                          Please input your email
                        </div>
                      ),
                    },
                  ]}
                >
                  <Input type={"email"} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    SUBMIT
                  </Button>
                </Form.Item>
              </Form>
            </FormWraper>

            {/* nitification model */}
            <NotificationModal
              visible={isModalVisible}
              onClose={closeModal}
              content={"We have sent you an email to reset your password."}
              icon={<SuccessIcon />}
            />
            <NotificationModal
              visible={isModalVisible2}
              onClose={closeModal}
              content={"Please fill the required field."}
              icon={<WarningIcon />}
            />
          </Col>
        </Row>
      </ForgetPasswordWraper>
    </>
  );
};

export default ForgotPassword;
