import styled from "styled-components";

export const AuthHeadingWraper = styled.div`
  .auth-heading {
    color: ${(props) => props.theme.colors.black_1000};
    line-height: 28px;
    margin: 0px 0px 20px 0px;
    font-family: "NunitoSansRegular";
    font-size: 24px;
    font-weight: 400;
  }
`;
