import styled from "styled-components";

export const ParagraphWraper = styled.div`
  .xlRegular {
    // color: ${(props) => props.theme.colors.white};
    // text-align: center;
    font-family: "NunitoSansRegular";
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    padding: 0px 0px 12px 0px;
    margin: 0px;
  }
  .xlBold {
    color: ${(props) => props.theme.colors.black_1000};
    font-family: "NunitoSansBold";
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    padding: 0px 0px 12px 0px;
    margin: 0px;
  }
  .lgRegular {
    color: ${(props) => props.theme.colors.black_800};
    font-family: "NunitoSansRegular";
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    margin: 0px;
  }
  .lgBold {
    color: ${(props) => props.theme.colors.black_1000};
    font-family: "NunitoSansBold";
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    padding: 0px 0px 12px 0px;
    margin: 0px;
  }
  .mdRegular {
    color: ${(props) => props.theme.colors.black_800};
       font-family: "NunitoSansLight" ;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0px 0px 12px 0px;
    letter-spacing: 0px;
  }
  .mdBold {
    color: ${(props) => props.theme.colors.black_1000};
    font-family: "NunitoSansBold";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px 0px 8px 0px;
  }
  .smRegular {
    color: ${(props) => props.theme.colors.black_800};
    font-family: "NunitoSansRegular";
    font-size: 14px;
    line-height: 30px;
    padding: 0px 0px 24px 0px;
    margin: 0px;
  }
  .smBold {
    color: #fff;
    text-align: center;
    font-family: "NunitoSansRegular";
    font-size: 14px;
    line-height: 30px;
    padding: 0px 0px 24px 0px;
    margin: 0px;
  }
  .xsRegular {
    color: ${(props) => props.theme.colors.black_800};
    font-family: "NunitoSansLight" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin: 0px 0px 12px 0px;
    letter-spacing: 0px;
  }
  .xsBold {
    color: ${(props) => props.theme.colors.black_1000};
    font-family: "NunitoSansBold";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px 0px 8px 0px;
  }
  .dashboardFont {
    color: ${(props) => props.theme.colors.black_1000};
    font-family: "NunitoSansMedium";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0px 0px 16px 0px;
  }
  .dashboardFont2 {
    color: ${(props) => props.theme.colors.black_1000};
    font-family: "NunitoSansRegular";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0px 0px 20px 0px;
  }
  .
`;
