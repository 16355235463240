import styled from "styled-components";
export const HeroButtonWraper = styled.div`
  display: flex;
  width: fit-content;

  button.hero-button {
    background-color: transparent;
    border: none;
    
    @media (max-width: 500px) {
      width: fit-content;
      margin: auto;
    }
    &:hover {
      background-color: transparent;
      border: none;
    }
  }
  .ant-btn {
    padding: 13px;
    height: fit-content;
  }
  .ant-btn-default {
    box-shadow: none;
  }
`;
