import styled from "styled-components";

export const ForgetPasswordWraper = styled.div`
  .Sub-heading {
    margin-bottom: 4px;
  }
  .smRegular {
    padding: 0px 0px 24px;
    font-style: normal;
    line-height: 21px;
  }
`;
