import { Button, Col, Radio, Row, Space, Table } from "antd";
import { data } from "src/Assets/Content/Recruitment/index";
import SuccessTableIcon from "src/Assets/Icons/SuccessTableIcon";
import ErrorTableIcon from "src/Assets/Icons/ErrorTableIcon";
import { RecruitmentWrapper, TableWraper } from "./styles";
import SubHeading from "src/Components/Shared/SubHeading";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "src/Components/Constant/routes";
import { useDispatch, useSelector } from "react-redux";
import { requestCompleted, requestStart, loading as stateLoading } from "src/Redux/App";
import { useLocation } from 'react-router-dom';
const columns = [
  {
    title: "Placement Services USA does the following:",
    dataIndex: "description",
    key: "description",
    width: 798,
  },
  {
    title: "Package 1",
    dataIndex: "package1",
    key: "package1",
    width: 166,
    className: "centered-cell",
    itleClassName: "centered-cell",
    render: (text: any) => {
      if (text === "yes") {
        return <SuccessTableIcon />;
      }
      return <ErrorTableIcon />;
    },
  },
  {
    title: "Package 2",
    dataIndex: "package2",
    key: "package2",
    width: 166,
    className: "centered-cell",
    itleClassName: "centered-cell",
    render: (text: any) => {
      if (text === "yes") {
        return <SuccessTableIcon />;
      }
      return <ErrorTableIcon />;
    },
  },
  {
    title: "Package 3",
    dataIndex: "package3",
    key: "package3",
    width: 166,
    className: "centered-cell",
    itleClassName: "centered-cell",
    render: (text: any) => {
      if (text === "yes") {
        return <SuccessTableIcon />;
      }
      return <ErrorTableIcon />;
    },
  },
];
interface PackageData {
  id: number;
  title: string;
  price: number;
  discription: string;
}
const Recruitment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  
  const [PackagesData, setPackagesData] = useState<PackageData[]>([]);
  const record = location.state && location.state.record;
  const [loadingData, setloadingData] = useState(false);
 console.log(record , "At tye slect page")
  useEffect(() => {
     if(record?.packagesId){
      setSelectedValue(record?.packagesId);
     }
    const fetchData = async () => {
      try {
        setloadingData(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/pakages/list`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            data: {},
          }
        );
        // Assuming the response data is an array of objects with properties: value, price, and label
        setPackagesData(response.data);
        setloadingData(false);
      } catch (error) {
        setloadingData(false);
        console.error("Error fetching radio data:", error);
      }
    };
     


    fetchData();
  }, []);

  // @ts-ignore
  const getRowClassName = (record:any, index: number) => {
    return index % 2 === 0 ? "table-row-White" : "table-row-lite";
  };
  const [selectedValue, setSelectedValue] = useState(record?.packagesId ? record?.packagesId : null);
  const handleRadioChange = (e: any) => {
    localStorage.setItem(
      "pakageId",
      String(PackagesData[e.target.value - 1].id)
    );
     console.log(e.target.value)
    setSelectedValue(e.target.value);
  };

  const postAJob = () => {
    if (selectedValue) {
      dispatch(requestStart());
      setTimeout(() => {
        dispatch(requestCompleted());
        navigate(AppRoutes.application, { state: { record } });
      }, 1000);
    }
  };
  return (
    <>
      <RecruitmentWrapper>
        <form>
          <Row>
            <Col md={18}>
              <SubHeading>Recruitment Packages</SubHeading>
              <div className="Radio-wrapper">
                <Radio.Group onChange={handleRadioChange} value={selectedValue}>
                  <Space direction="vertical">
                    {PackagesData.map((option) => (
                      <Radio key={option.id} value={option.id}>
                        <span className="price">${option.price}</span>
                        <span className="package-heading">
                          {option.title}:{" "}
                        </span>
                        <span className="label">{option.discription}</span>
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </div>
              <Button
                type="primary"
                onClick={() => postAJob()}
                // loading={loading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      </RecruitmentWrapper>
    </>
  );
};

export default Recruitment;
