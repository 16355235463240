interface PreviousProps {
  disabled: boolean;
}

export default function Previous({ disabled }: PreviousProps) {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.57505 1.40039L2.14172 6.83372C1.50005 7.47539 1.50005 8.52539 2.14172 9.16706L7.57505 14.6004"
        stroke={disabled ? "#C1BFBC" : "#ffffff"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
