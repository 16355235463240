import React from "react";
import { Modal } from "antd";
import Paragraph from "src/Components/Shared/Peragraph";
import { ModalWrapper } from "src/Styles/global";

interface MyModalProps {
  visible: boolean;
  content: any;
  icon: any;
  onClose: () => void;
}

const NotificationModal: React.FC<MyModalProps> = ({
  visible,
  onClose,
  content,
  icon,
}) => {
  const renderContent = (content: string) => {
    return content.split("/n").map((paragraph, index) => (
      <React.Fragment key={index}>
        {index > 0 && <br />}{" "}
        {/* Add a line break for each paragraph except the first one */}
        {paragraph}
      </React.Fragment>
    ));
  };
  return (
    <>
      <ModalWrapper>
        <Modal
          centered
          visible={visible}
          onOk={() => onClose()}
          onCancel={() => onClose()}
          footer={false}
          width={407}
        >
          <>
            <div className="upload-icon">{icon}</div>
            <Paragraph className="mdRegular">
              {renderContent(content)}
            </Paragraph>
          </>
        </Modal>
      </ModalWrapper>
    </>
  );
};

export default NotificationModal;
