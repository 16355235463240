import React from "react";

export default function ImageUploadIcon() {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.2738 37.147L36.3404 10.267C34.3338 6.65033 31.5571 4.66699 28.5004 4.66699C25.4438 4.66699 22.6671 6.65033 20.6604 10.267L5.7271 37.147C3.8371 40.577 3.6271 43.867 5.14377 46.457C6.66044 49.047 9.6471 50.4703 13.5671 50.4703H43.4338C47.3538 50.4703 50.3404 49.047 51.8571 46.457C53.3738 43.867 53.1638 40.5537 51.2738 37.147ZM26.7504 21.0003C26.7504 20.0437 27.5438 19.2503 28.5004 19.2503C29.4571 19.2503 30.2504 20.0437 30.2504 21.0003V32.667C30.2504 33.6237 29.4571 34.417 28.5004 34.417C27.5438 34.417 26.7504 33.6237 26.7504 32.667V21.0003ZM30.1571 41.3237C30.0404 41.417 29.9238 41.5103 29.8071 41.6037C29.6671 41.697 29.5271 41.767 29.3871 41.8137C29.2471 41.8837 29.1071 41.9303 28.9438 41.9537C28.8038 41.977 28.6404 42.0003 28.5004 42.0003C28.3604 42.0003 28.1971 41.977 28.0338 41.9537C27.8938 41.9303 27.7538 41.8837 27.6138 41.8137C27.4738 41.767 27.3338 41.697 27.1938 41.6037C27.0771 41.5103 26.9604 41.417 26.8438 41.3237C26.4238 40.8803 26.1671 40.2737 26.1671 39.667C26.1671 39.0603 26.4238 38.4537 26.8438 38.0103C26.9604 37.917 27.0771 37.8237 27.1938 37.7303C27.3338 37.637 27.4738 37.567 27.6138 37.5203C27.7538 37.4503 27.8938 37.4037 28.0338 37.3803C28.3371 37.3103 28.6638 37.3103 28.9438 37.3803C29.1071 37.4037 29.2471 37.4503 29.3871 37.5203C29.5271 37.567 29.6671 37.637 29.8071 37.7303C29.9238 37.8237 30.0404 37.917 30.1571 38.0103C30.5771 38.4537 30.8338 39.0603 30.8338 39.667C30.8338 40.2737 30.5771 40.8803 30.1571 41.3237Z"
        fill="#F43E5C"
      />
    </svg>
  );
}
