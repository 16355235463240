import ErrorIcon from "src/Assets/Icons/ErrorIcon";
import { ErrorIconWraper } from "./styles";

const ErrorMessage = ({ message }: any) => {
  return (
    <ErrorIconWraper>
      <ErrorIcon />
      {message}
    </ErrorIconWraper>
  );
};

export default ErrorMessage;
