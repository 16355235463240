import React from "react";
import { createGlobalStyle } from "styled-components";
import NunitoSansBold from "./Assets/Fonts/NunitoSans/NunitoSansBold.woff";
import NunitoSansMedium from "./Assets/Fonts/NunitoSans/NunitoSansMedium.woff";
import NunitoSansRegular from "./Assets/Fonts/NunitoSans/NunitoSansRegular.woff";
import NunitoSansLight from "./Assets/Fonts/NunitoSans/NunitoSansLight.ttf";
import PopinsRegular from "./Assets/Fonts/Poppins/Poppins-Regular.woff";
import PopinsMedium from "./Assets/Fonts/Poppins/Poppins-Medium.woff";
import PopinsBold from "./Assets/Fonts/Poppins/Poppins-Bold.woff";
import PopinsExtraBold from "./Assets/Fonts/Poppins/Poppins-ExtraBold.woff";

export const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'NunitoSansBold';
    src: url(${NunitoSansBold}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoSansMedium';
    src:  url(${NunitoSansMedium}) format('woff');
    font-weight: 500;
    font-style: normal;
  }   
  @font-face {
    font-family: 'NunitoSansRegular';
    src:  url(${NunitoSansRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoSansLight';
    src: url(${NunitoSansLight}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'PopinsRegular';
    src: url(${PopinsRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'PopinsMedium';
    src: url(${PopinsMedium}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'PopinsBold';
    src: url(${PopinsBold}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'PopinsExtraBold';
    src: url(${PopinsExtraBold}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
body{
    margin:0px;
}
p{
    margin:0px;
    font-family: 'NunitoSansLight';
}
h2{
  font-family: 'PopinsExtraBold';
}

///ant modal customization
.ant-modal{
  text-align:center;
}
.ant-modal .ant-modal-content {
  border-radius:4px;
  text-align: center;
  padding:32px 20px;
  .mdRegular{
    margin:8px 0px 0px 0px;
  }
}
.modal-custom .ant-modal-content {
  text-align: left !important; 
  margin-top: 60px;
  padding: 40px;
}
.ant-modal .ant-modal-footer {
  margin-top:0px;
}
:where(.css-dev-only-do-not-override-htwhyh).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  border:1px solid #ff9900;
  background-color: #ff9900;
  transition:all 0s;
}
:where(.css-dev-only-do-not-override-htwhyh).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: #ff9900;
}
`;
export default FontStyles;
