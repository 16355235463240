import { SubHeadingWraper } from "./styles";

const SubHeading = ({ children }: any) => {
  return (
    <SubHeadingWraper>
      <h2 className="Sub-heading">{children}</h2>
    </SubHeadingWraper>
  );
};

export default SubHeading;
