import styled from "styled-components";
export const StatusButtonWraper = styled.div`
display: flex;
    justify-content: center;
text-align:center;
    .pending-button {
    cursor: default;
      width:118px;
      font-family: "PopinsMedium"; 
      border:1px solid ${(props) => props.theme.colors.brown_200} ;
      border-radius:40px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      background-color: transparent;
      color: ${(props) => props.theme.colors.brown_200};
      padding: 5px 30px !important;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content:center;
      @media (max-width: 500px) {
        width: fit-content;
        margin: auto;
      }
      &:hover {
        color: ${(props) => props.theme.colors.brown_200} !important;
        border:1px solid ${(props) => props.theme.colors.brown_200} !important;
      }
    }
  }
  .processing-button {
    width:118px;
    font-family: "PopinsMedium"; 
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.greenPrimary};
    padding: 5px 0px !important;
    border:1px solid ${(props) => props.theme.colors.greenPrimary};
    border-radius: 40px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content:center;
    @media (max-width: 500px) {
      width: fit-content;
      margin: auto;
    }
    &:hover {
      color: ${(props) => props.theme.colors.greenPrimary} !important;
      border:1px solid ${(props) => props.theme.colors.greenPrimary} !important;
    }
  }
}
`;
